import request from '@/utils/request';

export default {
  namespaced: false,
  state: {
    baseInfo: {}, // 基本信息
    store: {}, // 网站信息
    payment: {}, //支付配置
    isShowFeedbackModal: false, // 意见反馈弹窗控制
    isShowReportModal: false, // 一键举报弹窗控制
  },
  getters: {
    init: (state) => state.baseInfo,
    initStore: (state) => state.store,
    initPayment: (state) => state.payment,
    isShowFeedbackModal: (state) => state.isShowFeedbackModal,
    isShowReportModal: (state) => state.isShowReportModal,
  },
  actions: {
    // 初始化数据
    async appInit({ commit }) {
      const result = await request.get('/api/init');
      if (result.code === 1) {
        commit('CONFIG', result.data);
        return result.data;
      }
      return false;
    },
    // 意见反馈弹窗控制
    showFeedbackModal({ commit }, data) {
      commit('FEEDBACK_MODAL', data);
    },
    // 一键举报弹窗控制
    showReportModal({ commit }, data) {
      commit('REPORT_MODAL', data);
    },
  },
  mutations: {
    CONFIG(state, payload) {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
    // 意见反馈弹窗控制
    FEEDBACK_MODAL(state, data) {
      state.isShowFeedbackModal = data;
    },
    // 意见反馈弹窗控制
    REPORT_MODAL(state, data) {
      state.isShowReportModal = data;
    },
  },
};
