export default {
  namespaced: true,
  state: {
    max: 5, // 最多可以对比的商品数目
    // 需要对比的商品列表
    prods: [
      {
        id: 1,
        name: '商品1',
        typeName: '手机',
        supplier: '京东自营',
        price: 10.6, //价格
        oldprice: 10, //历年中标价
        size: '5.5寸', //产品尺寸
        weight: '180g', //整机重量
        frequency: '2400MHz', //接收频率
        channel: '16', //通道数目
        accuracy: '30m', //定位精度
        time: '10s', //首次定位时间
        num: 6, //数量
        stock: 10, //库存
        img: 'https://picsum.photos/100',
      },
      {
        id: 2,
        name: '北斗信号弹TD10（北斗三号短报文应急示位搜救终端',
        typeName: '电脑',
        supplier: '京东自营',
        price: 9.9, //价格
        oldprice: 9.6, //历年中标价
        size: '13.3寸', //产品尺寸
        weight: '300g', //整机重量
        frequency: '2400MHz', //接收频率
        channel: '16', //通道数目
        accuracy: '30m', //定位精度
        time: '10s', //首次定位时间
        num: 10, //数量
        stock: 999, //库存
        img: 'https://picsum.photos/100',
      },
      {
        id: 3,
        name: '商品3',
        typeName: '相机',
        supplier: 'xxx品牌提供商',
        price: 30, //价格
        oldprice: 26.8, //历年中标价
        size: '5.5寸', //产品尺寸
        weight: '180g', //整机重量
        frequency: '2400MHz', //接收频率
        channel: '16', //通道数目
        accuracy: '30m', //定位精度
        time: '10s', //首次定位时间
        num: 2, //数量
        stock: 100, //库存
        img: 'https://picsum.photos/100',
      },
    ],
  },
  getters: {
    getProds(state) {
      return state.prods.length;
    },
  },
  actions: {},
  mutations: {
    deleteComparison(state, id) {
      const index = state.prods.findIndex((product) => product.id === id);
      if (index !== -1) {
        state.prods.splice(index, 1);
      }
    },
    emptyComparison(state) {
      state.prods = [];
    },
  },
};
