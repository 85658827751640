import Vue from 'vue';
import Vuex from 'vuex';
// 本插件用于持久化存储vuex状态
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

import init from './modules/init';
import carts from './modules/carts';
import comparison from './modules/comparison';
import user from './modules/user';

export default new Vuex.Store({
  modules: {
    // 注册模块
    init, // 初始化模块
    carts, // 购物车模块
    comparison, // 对比模块
    user, // 用户模块
  },
  plugins: [
    // 需要localStorage存储起来的模块
    createPersistedState({
      storage: window.localStorage,
      paths: ['user','carts', 'comparison'],
    }),
    // 需要sessionStorage存储起来的模块
    /* createPersistedState({
      storage: window.sessionStorage,
      paths: ['platform', 'upload']
    }) */
  ],
});

//state: 存储数据
//getters: 读取state 类似于computed
//mutations: 同步修改state 用于实现同步操作
//actions: 异步修改state 用于实现异步操作
//modules: 注册模块 用于管理复杂的状态
//modules中的state,getters,mutations,actions可以直接通过this.$store.state.moduleName.xxx来访问
