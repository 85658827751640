<template>
  <div>
    <!-- <el-button class="header-custom-btn" :style="{color: scrollState ? '#00161A' : '#fff'}" v-if="isLogin" @click="$store.dispatch('showAuthModal', 'login')">用户登录</el-button> -->
    <div class="user-info" v-if="!user" @click="toLogin">
      <el-avatar :src="require('@/assets/images/dfavatar.png')"></el-avatar>
      <div
        class="nickname ml-10"
        :style="{ color: scrollState ? '#00161A' : '#fff' }"
      >
        立即登录
      </div>
    </div>
    <div class="user-info" v-else @click="toMyIndex">
      <!-- <span @click="$router.push('/user/profile')"> -->
      <img :src="user.avatarUrl ? user.avatarUrl : require('@/assets/images/dfavatar.png')"></img>
      <div
        class="nickname"
        :style="{ color: scrollState ? '#00161A' : '#fff' }"
      >
        {{ user.username }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
import { userDetail } from "@/api/lx";
export default {
  components: {},
  props: {
    scrollState: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      user:null,
    };
  },
      computed: {
        ...mapGetters(['isLogin', 'userInfo'])
    },
  created() {
    let token = localStorage.getItem("token");
    if (token) {
      this.getUser();
    }
  },
  // computed: {
  //   ...mapGetters(["isLogin", "userInfo"]),
  // },
  methods: {
    refresh(){
      this.getUser()
    },
    getUser() {
      userDetail({}).then((res) => {
        this.user = res.data;
        localStorage.setItem("user", JSON.stringify(res.data));
        this.$store.dispatch('userInfo', res.data);
      });
    },
    toLogin() {
      this.$router.push("/login");
    },
    toMyIndex(){
      this.$router.push("/my/index");
    }
  },
};
</script>

<style scoped lang="less">
.user-info {
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .nickname {
    font-weight: 400;
    font-size: 18px;
    color: #00161a;
    line-height: 22px;
  }
}
</style>