export default {
  namespaced: true,
  state: {
    // 商品列表
    prods: [
      {
        id: 1,
        name: '商品1',
        typeName: '手机',
        supplier: '京东自营',
        price: 10.6, //价格
        num: 6, //数量
        stock: 10, //库存
        img: 'https://picsum.photos/100',
      },
      {
        id: 2,
        name: '北斗信号弹TD10（北斗三号短报文应急示位搜救终端',
        typeName: '电脑',
        supplier: '京东自营',
        price: 9.9, //价格
        num: 10, //数量
        stock: 999, //库存
        img: 'https://picsum.photos/100',
      },
      {
        id: 3,
        name: '商品3',
        typeName: '相机',
        supplier: 'xxx品牌提供商',
        price: 30, //价格
        num: 2, //数量
        stock: 100, //库存
        img: 'https://picsum.photos/100',
      },
    ],
    // 购物车
    carts: [],
  },
  getters: {
    // 计算总价
    getTotalPrice(state) {
      let total = 0;
      state.carts.forEach((item) => {
        total += item.price * item.num;
      });
      return total.toFixed(2);
    },
    // 计算总数量
    getTotalNum(state) {
      let total = 0;
      state.carts.forEach((item) => {
        total += item.num;
      });
      return total;
    },
  },
  actions: {},
  mutations: {
    // 添加商品到购物车
    addCart(state, payload) {
      const index = state.carts.findIndex((item) => item.id === payload.id);
      if (index === -1) {
        payload.num = 1;
        state.carts.push(payload);
      } else {
        state.carts[index].num++;
      }
    },
    // 从购物车中删除商品
    removeCart(state, id) {
      const index = state.carts.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.carts.splice(index, 1);
      }
    },
  },
};
