<template>
  <div id="app" class="app-wrap">
    <!-- 路由出口 -->
    <keep-alive :include="includeArr">
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
export default {
  watch: {
    $route(to, from) {
      // to: 新的路由对象
      // from: 旧的路由对象
      console.log("Route changed:", to.fullPath);
      if (
        to.fullPath.includes("/school/details") ||
        to.fullPath.includes("/school/index")
      ) {
        this.includeArr = ["schoolIndex"];
      } else if (
        to.fullPath.includes("/information/productManual") ||
        to.fullPath.includes("/information/productManualDetails")
      ) {
        this.includeArr = ["productManual"];
      } else if (
        to.fullPath.includes("/information/achievement") ||
        to.fullPath.includes("/information/achievementDetails")
      ) {
        this.includeArr = ["achievement"];
      } else if (
        to.fullPath.includes("/information/tool") ||
        to.fullPath.includes("/information/toolDetails")
      ) {
        this.includeArr = ["tool"];
      } else if (
        to.fullPath.includes("/news/index") ||
        to.fullPath.includes("/news/details")
      ) {
        this.includeArr = ["news"];
      } else {
        this.includeArr = [];
      }
    },
  },
  data() {
    return {
      includeArr: [],
    };
  },
  mouted() {},

  methods: {},
};
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f9fafc;
  font-family: PingFangSC, PingFang SC;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.min-100 {
  min-height: 100px !important;
}

.min-200 {
  min-height: 200px !important;
}

.min-300 {
  min-height: 300px !important;
}

.min-400 {
  min-height: 400px !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.p {
  padding: 10px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-sm {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-50 {
  padding: 50px !important;
}

.p-60 {
  padding: 60px !important;
}

.p-70 {
  padding: 70px !important;
}

.p-80 {
  padding: 80px !important;
}

.p-90 {
  padding: 90px !important;
}

.p-100 {
  padding: 100px !important;
}

.pt {
  padding-top: 10px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-sm {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pb {
  padding-bottom: 10px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-sm {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pl {
  padding-left: 10px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-sm {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pr {
  padding-right: 10px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-sm {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.m {
  margin: 10px !important;
}

.m-0 {
  margin: 0 !important;
}

.m-sm {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-50 {
  margin: 50px !important;
}

.m-60 {
  margin: 60px !important;
}

.m-70 {
  margin: 70px !important;
}

.m-80 {
  margin: 80px !important;
}

.m-90 {
  margin: 90px !important;
}

.m-100 {
  margin: 100px !important;
}

.mt {
  margin-top: 10px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-sm {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mb {
  margin-bottom: 10px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-sm {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.ml {
  margin-left: 10px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-sm {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.mr {
  margin-right: 10px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-sm {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.fl {
  float: left !important;
}

.fr {
  float: right !important;
}

.clear {
  clear: both !important;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-26 {
  font-size: 26px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-38 {
  font-size: 38px !important;
}

.font-40 {
  font-size: 40px !important;
}

.font-50 {
  font-size: 50px !important;
}

.font-60 {
  font-size: 60px !important;
}

.text-red {
  color: #ff0000 !important;
}

.text-yellow {
  color: #f37b1d !important;
}

.text-blue {
  color: #1890ff !important;
}

.text-green {
  color: #19be6b !important;
}

.text-info {
  color: #2db7f5 !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-gray {
  color: #999 !important;
}

/*间距*/
.height-auto {
  line-height: normal !important;
}

.height-18 {
  line-height: 18px !important;
}

.height-20 {
  line-height: 20px !important;
}

.height-22 {
  line-height: 22px !important;
}

.height-24 {
  line-height: 24px !important;
}

.height-26 {
  line-height: 26px !important;
}

.height-28 {
  line-height: 28px !important;
}

.height-30 {
  line-height: 30px !important;
}

.height-40 {
  line-height: 40px !important;
}

.height-50 {
  line-height: 50px !important;
}

.height-60 {
  line-height: 60px !important;
}

.radius {
  border-radius: 50%;
}

.radius img,
.radius svg {
  border-radius: 50%;
}

.radius-sm {
  border-radius: 4px;
}

.text-hide {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-hide-2,
.text-hide-3,
.text-hide-4,
.text-hide-5,
.text-hide-6 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-hide-2 {
  -webkit-line-clamp: 2;
}

.text-hide-3 {
  -webkit-line-clamp: 3;
}

.text-hide-4 {
  -webkit-line-clamp: 4;
}

.text-hide-5 {
  -webkit-line-clamp: 5;
}

.text-hide-6 {
  -webkit-line-clamp: 6;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-start {
  align-items: flex-start;
}

.align-items {
  align-items: center;
}

.center {
  justify-content: center;
}

.justify-content {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.flex-direction {
  flex-direction: column;
}

.shrink {
  flex-shrink: 0;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.shrink {
  flex-shrink: 0;
}

.container {
  width: 1400px;
  margin-right: auto;
  margin-left: auto;
}

.router-link-exact-active {
  text-decoration: none;
}

.custom-btn {
  width: 200px;
  height: 50px;
  border-radius: 10px !important;
  background-color: #ef312a !important;
}

.custom-btn:hover {
  opacity: 0.8;
}

/* 模态框 */
.cu-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1110;
  opacity: 0;
  outline: 0;
  text-align: center;
  transform: scale(1.185);
  backface-visibility: hidden;
  perspective: 2000px;
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease-in-out 0s;
  pointer-events: none;

  &::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  &.show {
    opacity: 1;
    transition-duration: 0.3s;
    transform: scale(1);
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: auto;
  }

  .cu-dialog {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    width: 680px;
    max-width: 100%;
    background-color: #f8f8f8;
    border-radius: 10px;
    overflow: hidden;

    > .cu-bar:first-child {
      .action {
        min-width: 100px;
        margin-right: 0;
        min-height: 100px;
      }
    }

    .head-box {
      margin-bottom: 15px;

      .head-title {
        font-size: 20px;
        font-weight: bold;
        color: #333;
      }
    }
  }

  &.bottom-modal {
    margin-bottom: -1000px;

    &::before {
      vertical-align: bottom;
    }

    &.show {
      margin-bottom: 0;
    }

    .cu-dialog {
      width: 100%;
      border-radius: 0;
    }
  }

  &.drawer-modal {
    transform: scale(1);
    display: flex;

    .cu-dialog {
      height: 100%;
      min-width: 200px;
      border-radius: 0;
      margin: initial;
      transition-duration: 0.3s;
    }

    &.justify-start {
      .cu-dialog {
        transform: translateX(-100%);
      }
    }

    &.justify-end {
      .cu-dialog {
        transform: translateX(100%);
      }
    }

    &.show {
      .cu-dialog {
        transform: translateX(0%);
      }
    }
  }
}

.userCenterWarp {
  padding: 30px;
  margin: 30px 0;
  background-color: #fff;

  .userCenterTitle {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    padding: 20px 15px;
    text-indent: 20px;
    color: #fff;
    margin-bottom: 15px;
    background-color: #ef312a;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 15px;
      width: 4px;
      height: 14px;
      border-radius: 4px;
      background-color: #fff;
      transform: translateY(-50%);
    }
  }
}

.user-breadcrumb,
.breadcrumb {
  font-size: 18px !important;
  margin-bottom: 30px;

  .el-breadcrumb__item:last-child {
    .el-breadcrumb__inner {
      color: #ef312a;
    }
  }

  .el-breadcrumb__item:first-child {
    .el-breadcrumb__inner {
      color: #333;
    }
  }
}

.breadcrumb {
  font-size: 16px !important;
}

.el-pagination {
  .btn-prev {
    width: 40px !important;
    height: 40px !important;
  }
  .btn-next {
    width: 40px !important;
    height: 40px !important;
  }
  .el-pager {
    .btn-quicknext {
      line-height: 50px;
    }
    li {
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 2px;
      margin: 0 5px;
      color: #00d8c3;
      &:hover {
        color: #00d8c3 !important;
      }
      &.active {
        background-color: #00d8c3 !important;
        color: #fff;
      }
    }
  }
}
// 解决ueditor的fixedlayer层级问题
#edui_fixedlayer {
  z-index: 9999999 !important;
}
.textOverflowOne {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.textOverflowTwo {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
}
.hoverTransform {
  transition: transform 0.8s ease;
  &:hover {
    transform: scale(1.1);
  }
}
@font-face {
  font-family: "MyCustomFont" !important;
  src: url("../public/FZZZHONGJW.TTF");
}
.MyCustomFont {
  font-family: "MyCustomFont", Arial, sans-serif !important;
}
//全局修改面包屑的样式
.user-breadcrumb,
.breadcrumb {
  margin-bottom: 0;
  cursor: pointer;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #00d8c3 !important;
  cursor: pointer !important;
  font-weight: normal !important;
}
.el-breadcrumb__inner {
  font-size: 16px; /* 修改为你需要的字体大小 */
  font-weight: normal !important;
}
.el-breadcrumb__separator {
  font-size: 16px !important; /* 修改为你需要的图标大小 */
}
.breadcrumb {
  width: 100%;
  cursor: pointer;
  height: 62px;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #f3f6f8;
  padding-left: 45px;
  box-sizing: border-box;
}

/* 定义滚动条的整体部分 */
::-webkit-scrollbar {
  width: 10px; /* 滚动条宽度 */
}

/* 定义滚动条轨道 */
::-webkit-scrollbar-track {
  background: transparent; /* 轨道背景颜色 */
}

/* 定义滑块 */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* 滑块颜色 */
  border-radius: 10px; /* 滑块圆角 */
}

/* 当鼠标悬停在滚动条上时 */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4); /* 滑块悬停时的颜色 */
}
</style>