<template>
  <div>
    <img class="imgNone" src="@/assets/images/none.png" />
  </div>
</template>

<script>
export default {
  name: "none",
  data() {
    return {
      input: "",
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.imgNone {
  display: block;
  width: 400px;
  height: 262px;
  margin: 0 auto;
  margin-top: 84px;
  margin-bottom: 316px;
}
</style>