<template>
  <div class="footContent">
    <footer class="footer" v-if="!token">
      <div class="footerBox">
        <div class="logoBox">
          <!-- <img class="logo1" src="../assets/img/sssssssss/logoB.png" />
                    <div class="line"></div> -->
          <img class="logo2" src="../assets/img/sssssssss/logoB2.png" />
        </div>
        <div class="formBox">
          <div class="title">我想加入</div>
          <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
            <el-form-item prop="companyName">
              <el-input
                class="input1"
                v-model="ruleForm.companyName"
                maxlength="60"
                placeholder="请输入您的公司名称"
              ></el-input>
            </el-form-item>
            <div class="box2">
              <el-form-item prop="username">
                <el-input
                  class="input2"
                  v-model="ruleForm.username"
                  maxlength="20"
                  placeholder="请输入联系人姓名"
                ></el-input>
              </el-form-item>
              <el-form-item prop="phone">
                <el-input
                  class="input2"
                  v-model="ruleForm.phone"
                  maxlength="20"
                  placeholder="请输入您的联系电话"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="button" @click="submitForm('ruleForm')"
                  >提交</el-button
                >
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="ewmBox">
          <div class="ewmBoxTit">公众号</div>
          <img src="@/assets/images/ewm.png" />
        </div>
        <!-- <div class="infoBox" v-if="details">
            <div class="infoList">
            <div class="infoTitle">E-MALL</div>
            <div class="infoText">{{ details.email }}</div>
          </div>
            <div class="infoList">
              <div class="infoTitle">TEL</div>
              <div class="infoText">{{ details.telephone }}</div>
            </div>
            <div class="infoList">
              <div class="infoTitle">ADDRESS</div>
              <div class="infoText">{{ details.address }}</div>
            </div>
          </div> -->
      </div>
    </footer>
    <div class="copyrighFoot">
      <div class="copyright">
        <!-- <span class="link" @click="open(0)">用户条款</span>
        <span class="link" @click="open(1)">隐私声明</span> -->
        <span class="link">
          <div class="telBox">技术支持热线<img src="@/assets/images/telIcon.png" />400-99-02706</div>
        </span> 
        <span class="copyrightText"
          >©2016-2021上海良信电器股份有限公司沪ICP备17037559号-1</span
        >
        <span class="link ml-20 ewmHover" style="position: relative" v-if="token">
          <img class="ewm" src="@/assets/images/ewm.png" />
          公众号二维码
        </span>
      </div>
    </div>

    <el-dialog
      :title="
        richType == 0
          ? '《良信L-Xpert合作伙伴线上平台用户条款》'
          : '《良信L-Xpert合作伙伴线上平台隐私声明》'
      "
      :visible.sync="dialogVisible"
      width="50%"
    >
      <div class="rich" v-html="rich"></div>
    </el-dialog>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { joinCommit, bottomParam } from "@/api/lx";
import { agreementView } from "@/api/lx";
export default {
  name: "Footer",
  props: {
    baseInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    // var validatePass2 = (rule, value, callback) => {
    //   if (value === "") {
    //     return callback(new Error("请输入您的联系电话"));
    //   } else if (!/^1[3456789]\d{9}$/.test(value)) {
    //     callback(new Error("手机号格式不正确"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      token: localStorage.getItem("token"),
      dialogVisible: false,
      rich: "",
      richType: 0, //0《用户条款》 1《隐私声明》
      ruleForm: {
        companyName: "",
        username: "",
        phone: "",
      },
      details: null,
      rules: {
        companyName: [
          { required: true, message: "请输入您的公司名称", trigger: "blur" },
        ],
        username: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入您的联系电话", trigger: "blur" },
        ],
      },
      preventState: false,
    };
  },
  created() {
    // this.getBottomParam();
  },
  methods: {
    open(val) {
      this.richType = val;
      this.getAgreementView();
    },
    getAgreementView() {
      agreementView().then(async (res) => {
        if (this.richType == 0) {
          this.rich = res.data.agreement;
        } else {
          this.rich = res.data.privacyPolicy;
        }

        this.dialogVisible = true;
      });
    },
    getBottomParam() {
      bottomParam().then((res) => {
        if (res.code == 200) {
          this.details = res.data;
        }
      });
    },

    // 表单提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.preventState) return;
          this.preventState = true;
          console.log();
          joinCommit({
            ...this.ruleForm,
          })
            .then(async (res) => {
              if (res.code === 200) {
                console.log(this.ruleForm, "submit!");
                Message.success("提交成功");
                this.ruleForm = {
                  companyName: "",
                  username: "",
                  phone: "",
                };
                this.preventState = false;
              }
            })
            .catch(() => {
              this.preventState = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.footContent {
  background-color: #fff;
}

.footer {
  background-color: #f3f3f3;
  padding: 20px 0 26px;

  .footerBox {
    display: flex;
    align-items: center;
    justify-content: center;

    .logoBox {
      margin-right: 36px;
      height: 100%;
      // .logo1 {
      //     width: 163px;
      //     height: 38px;
      // }

      // .line {
      //     width: 140px;
      //     height: 1px;
      //     background: #FFFFFF;
      //     margin: 33px 0;
      // }

      .logo2 {
        width: 307px;
        height: 64px;
        margin-bottom: 27px;
        margin-top: 20px;
      }
    }

    .formBox {
      margin-right: 45px;

      .title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 20px;
        color: #00161a;
        line-height: 28px;
        margin-bottom: 22px;
      }

      .input1 {
        width: 660px;
      }

      .box2 {
        display: flex;
        align-items: center;

        .input2 {
          width: 250px;
          margin-right: 21px;
        }

        .button {
          width: 117px;
          height: 38px;
          background: #00d8c3;
          border-radius: 4px;
          font-weight: 600;
          font-size: 14px;
          color: #ffffff;
          text-align: center;
        }

        /deep/.el-button {
          border: 0 !important;
        }
      }

      /deep/.el-input__inner {
        border: 1px solid #e6edf0 !important;
      }
    }

    .infoBox {
      width: 400px;

      .infoList {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0 !important;
        }

        .infoTitle {
          font-size: 12px;
          color: #525f66;
          line-height: 17px;
          margin-bottom: 3px;
        }

        .infoText {
          font-size: 14px;
          color: #00161a;
          line-height: 20px;
        }
      }
    }
  }
}

.copyrighFoot {
  background-color: #fff;

  .copyright {
    height: 53px;
    width: 1400px;
    margin: 0 auto;
    // text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .link {
      font-weight: 400;
      font-size: 14px;
      color: #00161a;
      line-height: 53px;
      margin-right: 40px;
      cursor: pointer;
    }

    .copyrightText {
      font-weight: 400;
      font-size: 14px;
      line-height: 53px;
      color: #829199;
    }
  }
}
.ewmBox {
  width: 112px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  .ewmBoxTit {
    width: 100%;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #00161a;
  }
  img {
    width: 112px;
    height: 112px;
  }
}
.ewmHover{
  &:hover{
    .ewm{
      display: block;
    }
  }
}
.ewm {
  width: 112px;
  height: 112px;
  position: absolute;
  top: -112px;
  left: -12px;
  display: none;
}
.footContentMt{
  margin-top: 150px;
}
.telBox{
  color: #829199 !important;
  font-size: 14px;
  display: flex;
  align-items: center;
  img{
    width: 12px;
    height: 11.4px;
    margin-left: 5px;
    margin-right: 15px;
    display: block;
    margin-top: 1.5px;
  }
}
</style>