/**
 *  全局配置文件
 */

// 接口根域名 http://xiaofang.wuyi.link/api
export const BASE_URL = '';

// 接口路径
export const API_URL = "api"

// 全局网络图片地址变量，css背景图片地址变量在uni.scss中定义
export const IMG_URL = '';
