import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import None from './components/none.vue'; // 引入组件
Vue.component('None', None);

// 引入阿里iconfont
import '@/assets/aliiconfont/iconfont.js';
//引入svg组件
import IconSvg from './components/icon-svg.vue'
//全局注册icon-svg
Vue.component('icon-svg', IconSvg);

//import '@/assets/aliiconfont/iconfont.css';

// 注册全局组件
import headers from './components/header.vue';
import Lheaders from './components/Lheader.vue';
import footers from './components/footer.vue';

Vue.component('headers', headers);
Vue.component('Lheaders', Lheaders);

Vue.component('footers', footers);
Vue.use(ElementUI);

// 去掉开发模式下的控制台提示
Vue.config.productionTip = false;


// 屏幕自适应穿透px为rem
const baseSize = 16; // 设计稿根字体大小
function setRem() {
  const scale = document.documentElement.clientWidth / 1920; // 计算缩放比例
  document.documentElement.style.fontSize =
    baseSize * Math.min(scale, 2) + "px"; // 设置根字体大小
}

setRem();
window.addEventListener("resize", setRem); // 窗口大小改变时重新计算

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
