import request from '@/utils/request';
export default {
  namespaced: false,
  state: {
    token: localStorage.getItem('token') || '',
    isLogin: localStorage.getItem('isLogin') || false, // 是否登录
    userInfo: {}, // 用户信息
    userData: {}, // 用户其他相关信息
    authType: '', // login:登录, register:注册
  },
  getters: {
    token: (state) => state.token,
    isLogin: (state) => state.isLogin,
    userInfo: (state) => state.userInfo,
    userOtherData: (state) => state.userData,
    authType: (state) => state.authType,
  },
  actions: {
    // 获取用户信息
    /* getUserInfo({ commit, dispatch, getters, state }, token = '') {
      return new Promise((resolve, reject) => {
        token && localStorage.setItem('token', token);
        request
          .get('/api/user/info')
          .then((res) => {
            if (res.code === 1) {
              commit('userInfo', res.data);
              commit('isLogin', true);
              dispatch('showAuthModal', '');
              resolve(res.data);
            }
          })
          .then(() => {
            // 只有在登录的时候请求购物车信息，订单信息，获取登录信息之后。
            token && dispatch('getCartList');
            token && dispatch('getOrders');
          })
          .catch((err) => {
            reject(err);
          });
      });
    }, */
    // 用户其他相关信息
    /* getUserData({ commit }) {
      return new Promise((resolve, reject) => {
        request
          .get('/api/user/other')
          .then((res) => {
            commit('USER_DATA', res.data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }, */
    // 登录弹窗控制
    showAuthModal({ commit }, type = 'login') {
        commit('AUTH_TYPE', type);
    },
    // 退出登录
    logout({ commit, dispatch }) {
        localStorage.getItem('token') && request.post('/api/user/logout');
        commit('token', '');
        commit('isLogin', false);
        commit('userInfo', {});
        commit('CART_LIST', []);
        commit('USER_DATA', {});
    },
  },
  mutations: {
    token(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    // 登录状态
    isLogin(state, isLogin) {
      state.isLogin = isLogin;
      localStorage.setItem('isLogin', isLogin);
    },
    // 用户信息
    userInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    // 用户其他相关信息
    USER_DATA(state, userData) {
      state.userData = userData;
    },
    // 登录弹窗控制
    AUTH_TYPE(state, type) {
      state.authType = type;
    },
  },
};
