<template>
    <div style="" class="headers">
      <div class="header flex" style="justify-content: space-between;">
        <div class="left flex">
          <img class="img" src="../assets/img/2.png" alt="" />
          <div class="title">xxx消防设备网站</div>
        </div>
        <div class="right flex">
          <div class="anniu" @click="dialogVisible = true">一键举报</div>
          <div class="annius">供应商入驻</div>
          <div class="annius">供应商后台</div>
          <div class="annius">运维后台</div>
          <div class="annius">用户登录</div>
        </div>
      </div>
      <el-dialog
        title="一键举报"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose"
        :center="true"
      >
        <div>
          <div class="flex">
            <div class="flex">
              <span style="color: red">*</span>
              <span>举报类型</span>
            </div>
            <div style="padding-left: 10px; width: 80%">
              <el-select
                style="width: 100%"
                v-model="value"
                placeholder="请选择举报类型"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="flex marTop30">
            <div class="flex">
              <span style="color: red">*</span>
              <span>举报厂商</span>
            </div>
            <div style="padding-left: 10px; width: 80%">
              <el-select
                style="width: 100%"
                v-model="value"
                placeholder="请选择举报厂商"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="flex marTop30" style="align-items: flex-start">
            <div class="flex">
              <span style="color: red">*</span>
              <span>举报理由</span>
            </div>
            <div style="padding-left: 10px; width: 80%">
              <el-input
                type="textarea"
                :rows="8"
                placeholder="请输入内容"
                v-model="textarea"
              >
              </el-input>
            </div>
          </div>
          <div class="flex" style="margin-top: 15px">
            <div style="margin-left: 71px">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
  
                <div v-else style="border-radius: 10px">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <div class="el-upload__text">上传图片</div>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button
            style="background-color: #ef312a"
            type="primary"
            @click="confirm"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dialogVisible: false,
        options: [
          {
            value: "选项1",
            label: "黄金糕",
          },
          {
            value: "选项2",
            label: "双皮奶",
          },
          {
            value: "选项3",
            label: "蚵仔煎",
          },
          {
            value: "选项4",
            label: "龙须面",
          },
          {
            value: "选项5",
            label: "北京烤鸭",
          },
        ],
        value: "",
      };
    },
    methods: {
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {},
      handleClose(done) {
        this.$confirm("确认关闭？")
          .then((_) => {
            done();
          })
          .catch((_) => {});
      },
      confirm() {
        this.dialogVisible = false;
      },
    },
  };
  </script>
  
  <style scoped lang="less">
  .img {
    height: 60px;
    width: 60px;
  }
  .title {
    font-weight: normal;
    font-size: 24px;
    color: #2E3033;
  }
  .header {
    width: 75%;
    margin: 0 auto;
  }
  .headers {
    // background: rgba(255, 255, 255, 0.1);
    /deep/.el-button--primary {
      background-color: #ef312a;
      border-color: #ef312a;
      width: 150px;
    }
    /deep/.el-button.el-button--default {
      width: 150px;
    }
  }
  .right {
    .anniu {
      font-weight: normal;
      font-size: 16px;
      color: #FFFFFF;
      padding: 9px 15px;
      border: 1px solid #EF312A;
      border-radius: 5px;
      cursor: pointer;
      background-color: #EF312A;
    }
    .annius {
      font-weight: normal;
      font-size: 16px;
      color: #2E3033;
      margin-left: 60px;
      cursor: pointer;
    }
  }
  .avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 10px;
    padding-bottom: 5px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 14px;
    color: #8c939d;
    width: 80px;
    height: 60px;
    line-height: 80px;
    text-align: center;
  }
  .avatar {
    width: 80px;
    height: 60px;
    display: block;
  }
  </style>